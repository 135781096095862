import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import EditIcon from "@material-ui/icons/Today";
import { useStyles } from "./styles";
import EditTicketForm from "../EditTicketForm";
import PropTypes from "prop-types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Card, Grid, Box, Typography } from "@material-ui/core";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import CredentialStatusChange from "../../Credentials/CredentialStatusChange";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import TicketService from "../../../services/TicketService";
import TransactionManagerService from "../../../services/TransactionManagerService";
import apiClient from "../../../auth/apiClient";
import { useSelector } from "react-redux";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectContextEntity} from "../../../state/slices/CoreEntity";

const statusOptions = [
  { id: "void", name: "VOID" },
  { id: "payment", name: "PAID" },
  { id: "exit", name: "OUT" },
  { id: "enter", name: "IN" }
];

const ticketService = new TicketService(apiClient);

const SingleTicket = ({
  currentTicket,
  hideNavigation,
  fullWidth,
  className,
  ...props
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const EditTicketsPermission = hasPermissions(["inventory.edit"]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [stateTicket, setStateTicket] = useState(currentTicket);
  const useCoreEntitySlice = useCoreEntityContext();
  const timeZone = useSelector((state) => {
    const tz = useCoreEntitySlice ? selectContextEntity(state).details?.timezone : state.entities.Context?.details?.timezone;
    return typeof tz === "string" ? state.entities.Context?.details?.timezone : moment.tz.guess();
  });

  const [editingMode, setEditingMode] = React.useState(false);
  const cancelEditing = () => {
    setEditingMode(false);
  };
  const endEditing = () => {
    setEditingMode(false);
  };

  const formattedStartTime = moment
    .utc(stateTicket.issuedDate)
    .tz(timeZone)
    .format("llll");

  const formattedEndTime = moment
    .utc(stateTicket.expiredDate)
    .tz(timeZone)
    .format("llll");

  const handleStatusSubmit = async ({ status, reason }) => {
    stateTicket.activityDate = moment(Date.now()).format(
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    if (status?.toLowerCase() === "void") {
      stateTicket.expiredDate = stateTicket.activityDate;
    }
    stateTicket.status = status;
    stateTicket.statusChangeReason = reason;

    await handleUpdateTicket(stateTicket);
  };

  const handleUpdateTicket = async ticket => {
    let response;
    try {
      response = await ticketService.updateTicket(ticket);
    } catch (err) {
      if (
        err.response?.status === 400 &&
        typeof err.response?.data === "string"
      ) {
        enqueueSnackbar(err.response.data, { variant: "warning" });
        return;
      } else {
        enqueueSnackbar("Failed to update status of ticket", {
          variant: "error",
          tag: "FailedToUpdateTicketStatus"
        });
        return;
      }
    }

    setStateTicket(response.data);
    enqueueSnackbar("Ticket successfully updated", { variant: "success" });
  };

  return (
    <div>
      {!hideNavigation && (
        <div className={classes.sendMeBack}>
          <Button
            data-id="go-back-button"
            size="medium"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => history.goBack()}
          >
            Inventory
          </Button>
        </div>
      )}
      <Card
        classes={{ root: className }}
        className={clsx(
          fullWidth ? [classes.noMargin, classes.stretch] : "",
          classes.root
        )}
      >
        <div className={classes.heading}>
          {stateTicket.status != "Backout without ticket" &&
            stateTicket.status != "Backout with ticket" && (
              <CredentialStatusChange
                data-id="ticket-status-split-btn"
                disabled={!EditTicketsPermission}
                className={classes.statusSelector}
                statusOptions={statusOptions}
                onSubmit={handleStatusSubmit}
                defaultSelected={
                  stateTicket.status?.toLowerCase() === "lost ticket"
                    ? "Payment"
                    : stateTicket.status
                }
              />
            )}
        </div>
        <Box className={classes.centered}>
          <Typography
            className={clsx(classes.title, classes.goUpper)}
            color="textSecondary"
            gutterBottom
          >
            Ticket Number
          </Typography>
          <Typography data-id="ticket-id" variant="h5" color="primary">
            <span className={classes.goUpper}>
              {stateTicket.ticketID.slice(0, stateTicket.ticketID.length - 12)}
              <b>
                {stateTicket.ticketID.slice(stateTicket.ticketID.length - 12)}
              </b>
            </span>
          </Typography>
        </Box>
        <Grid container className={classes.datesContainer} spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              name="start time"
              color="textSecondary"
              className={clsx(classes.centered, classes.labelTitle)}
            >
              Start Time
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              textAlign="center"
              justifyContent="space-around"
            >
              <Typography
                data-id="issued-date"
                variant="h6"
                className={classes.centered}
                name="issued date"
              >
                {formattedStartTime}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              color="textSecondary"
              className={clsx(classes.labelTitle, classes.centered)}
            >
              End Time
            </Typography>
            {editingMode && EditTicketsPermission ? (
              <EditTicketForm
                currentTicket={stateTicket}
                cancelEditing={cancelEditing}
                onTicketSubmit={handleUpdateTicket}
                endEditing={endEditing}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                textAlign="center"
                justifyContent="space-around"
              >
                <Typography
                  data-id="expired-date"
                  variant="h6"
                  className={classes.centered}
                  name="expired date"
                >
                  {stateTicket.expiredDate === null ||
                  stateTicket.status?.toLowerCase() === "enter" ? (
                    <span className={classes.active} alt="Close Out Ticket">
                      Active
                    </span>
                  ) : (
                    formattedEndTime
                  )}
                  {EditTicketsPermission && (
                    <IconButton
                      name="edit button"
                      onClick={() => setEditingMode(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

EditTicketForm.propTypes = {
  format: PropTypes.string,
  hideNavigation: PropTypes.bool,
  fullWidth: PropTypes.bool
};

export default SingleTicket;
